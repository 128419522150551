<template>

    <!-- Контейнер для першого рядка з обмеженнями та пошуком -->
    <b-card>
        <b-row id="step1">
            <div class="col" style="display:flex" id="limits">

                <!-- Випадаючий список для вибору кількості елементів на сторінці -->
                <!-- <div class="pagination-wrap hstack gap-2" style="margin-right:5px;">
                    <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                        <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
                    </select>
                </div> -->

                <!-- 
                    Пошук
                    - @searchB - подія, яка спрацьовує при пошуку
                    - йде перевірка на objParams, та чи містить він 'search', якщо ні, поле пошуку не буде відображено 
                -->
                <searchBox 
                    @searchB="searchB" 
                />

            </div>
        </b-row>
    </b-card>

    <div class="row g-0 text-center text-sm-start align-items-center mb-4" >
        <div class="col-sm-6" >
            <div >
                <p class="mb-sm-0 text-muted">
                    {{ $t('Total') }} 
                    <span class="fw-semibold">
                        {{ this.form.show }}
                    </span> 
                    {{ $t('outOf') }} 
                    <span class="fw-semibold text-decoration-underline">{{ this.form.total }}</span>
                </p>
            </div>
        </div>
        
        <div class="col-sm-6" >
            <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                <div class="pagination-wrap hstack gap-2" style="margin-right:5px;" >

                    <!-- Випадаючий список для вибору кількості елементів на сторінці -->
                    <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                        <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
                    </select>

                </div>

                <li v-for="(pageNumber, index) in this.form.links" :key="index" :class="`page-item copy ${pageNumber.current == 'true' ? 'active' : null}`">
                    <a class="page-link" @click="getdata(pageNumber.page)">{{ pageNumber.page }}</a>
                </li>
                
            </ul>
        </div>
    </div>

    <b-row>
        <template v-for="item in this.form.items" :key="item">
            <div class="col-xxl-4 col-lg-6" >
                <div class="card card-height-100 card-animate" >

                    <div class="card-header align-items-center" >
                        <div class="d-flex align-items-center" >
                            <div class="flex-grow-1 d-flex align-items-center">
                                <div class="avatar-sm flex-shrink-0" >
                                    <span class="avatar-title bg-light text-primary rounded-circle fs-3">
                                        <i class="bx bx-user align-middle"></i>
                                    </span>
                                </div>
                                <div class="flex-grow-1 ms-3" >
                                    <p class="text-uppercase fw-semibold fs-12 text-muted mb-1"> {{ item.number }}</p>
                                    <h4 class=" mb-0">{{ $t('Total') }}: <span class="counter-value">{{ item.total }}</span> </h4>
                                </div>
                            </div>
                            <div class="flex-shrink-0">
                                
                                <!-- Кнопка відкриття тікетів по клієнту -->
                                <button 
                                    id="info" 
                                    type="button" 
                                    class="btn btn-outline-info waves-effect waves-light"
                                    @click="openTicketsPhone(item.number)"
                                >
                                        <i class="ri-ticket-2-line"></i>
                                </button>

                                <!-- Кнопка відкриття картки клієнта -->
                                <button 
                                    id="success" 
                                    type="button" 
                                    class="btn btn-outline-success waves-effect waves-light" 
                                    style="margin-left: 10px;"
                                    @click="openClient(item.number)"
                                >
                                    <i class="ri-user-line"></i>
                                </button>
                            </div>
                        </div>
                        
                    </div>

                    <div class="card-body" >

                        <b-row>
                            <b-col v-if="item.problem">
                                <h6 class="text-muted mb-3 fs-12 fw-semibold">{{ $t('Problems') }} - {{ item.problem.total }} тікетів</h6>
                                <div class="mt-3" >
                                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2" >
                                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-success align-middle me-2"></i> {{ $t('priorityLow') }}</p>
                                        <div >
                                            <span class="text-muted pe-2">{{ item.problem.priority.low }}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2" >
                                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-warning align-middle me-2"></i> {{ $t('priorityMedium') }}</p>
                                        <div >
                                            <span class="text-muted pe-2">{{ item.problem.priority.average }} </span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2" >
                                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-danger align-middle me-2"></i> {{ $t('priorityHigh') }}</p>
                                        <div >
                                            <span class="text-muted pe-2">{{ item.problem.priority.high }} </span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between py-2" >
                                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-danger align-middle me-2"></i> {{ $t('Critical') }}</p>
                                        <div >
                                            <span class="text-muted pe-2">{{ item.problem.priority.critical }} </span>
                                        </div>
                                    </div>
                                </div>
                            </b-col>

                            <b-col v-if="item.wishes">
                                <h6 class="text-muted mb-3 fs-12 fw-semibold">{{ $t('wish_to_develop') }} - {{ item.wishes ? item.wishes.total : 0 }} тікетів</h6>
                                <div class="mt-3" >
                                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2" >
                                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-success align-middle me-2"></i> {{ $t('priorityLow') }}</p>
                                        <div >
                                            <span class="text-muted pe-2">{{ item.wishes.priority.low }} </span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2" >
                                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-warning align-middle me-2"></i> {{ $t('priorityMedium') }}</p>
                                        <div >
                                            <span class="text-muted pe-2">{{ item.wishes.priority.average }} </span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2" >
                                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-danger align-middle me-2"></i> {{ $t('priorityHigh') }}</p>
                                        <div >
                                            <span class="text-muted pe-2">{{ item.wishes.priority.high }} </span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between py-2" >
                                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-danger align-middle me-2"></i> {{ $t('Critical') }}</p>
                                        <div >
                                            <span class="text-muted pe-2">{{ item.wishes.priority.critical }} </span>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                    </div>


                </div>
            </div>
        </template>

    </b-row>

    <!-- Модальне вікно з тікетами по клієнту -->
    <unclodesTicketsView 
        v-if="showView"
        @close="showView = false"
        :objCard="clientTickets"
    />

</template>

<script>
import searchBox from "@/components/globaltable/searchbox.vue";
import unclodesTicketsView from "./viewUnclosedTickets/view"
import { Tickets } from '@/API.js';

let apiServe = new Tickets();

export default {
    props: ['objData'],
    components: {
        searchBox,
        unclodesTicketsView
    },
    data() {
        return {
            form: {},
            limit: "5", // Рядок, що вказує обрану кількість елементів на сторінці.
            limits: [ // Масив об'єктів, що містять варіанти кількості елементів на сторінці.
                { value: '5'},
                { value: '10'},
                { value: '25'},
                { value: '50'},
                { value: '75'},
                { value: '100'},
                { value: this.$t('all')},
            ],
            objParams: {
                page: '1',
                pagelimit: '5',
                more: '2',
                search: '',
                of: 'total',
                ot: 'desc'
            },
            searchTime: '', // Ідентифікатор таймера для затримки пошуку
            showView: false, // відображення вікна тікетів по телефону
            number: '', // телефон клієнта
            clientTickets: {
                type: 'ticketsClient', // тип
                title: '', // телефон клієнта
                data: {}, // дані тікетів
                filter: '' // фільтр для модалки
            },
        }
    },
    created(){
        // отримання даних
        this.getdata()
        
    },
    methods: {
        getdata(page){
            // Отримання даних

            this.objParams.page = page != undefined ? page : '1';
            
            apiServe.getUnclosedTickets(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.form = result.data;
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
            
        },
        searchB(e){
            // Обробник події пошуку

            clearTimeout(this.searchTime)
            this.objParams.search = e;
            this.searchTime = setTimeout(() => this.getdata(), 500);
        },
        changelimit(limit){
            // Подія для зміни кількості елементів на сторінці
            
            if(this.form.links && limit == this.$t('all')){
                this.objParams.pagelimit = this.form.total
            } else {
                this.objParams.pagelimit = limit;
            }
            
            this.getdata()
        },
        openTicketsPhone(phone){
            // Отримання відкритих тікетів по номеру телефона

            var objParamsPhone = {
                page: '1',
                pagelimit: '1000',
                search: phone,
            }

            apiServe.getTickets(objParamsPhone).then(result => {
                if(result.status == "done") {
                    this.clientTickets.data = result.data;
                    this.clientTickets.title = phone;
                    this.clientTickets.filter = phone;
                    this.showView = true
                } else {

                }

            })
        },
        openClient(num){
            // Відкриваємо картку клієнта

            // додаємо можливість пошуку, якщо номер вказаний без 38
            var customNumber = num.toString();
            var numcode = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "091", "092", "089", "094"]
            for(var item in numcode) {
                if(customNumber.substr(0, 3) == numcode[item] ) {
                    customNumber = "38" + num
                }
            }

            this.number = customNumber
            this.eventBus.emit('getClient', {status: true, number: this.number})
        }
    }
}

</script>